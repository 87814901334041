<template>
    <div class="common-table search-result">
        <div class="common-table__title flex">
            总览分析图
            <ts-table-operate :hide-search="tab !== 'province'" :cols="cols" @searchShowLeft="searchShowLeft"
                @handleFilter="handleFilter" @leadingOut="leadingOut" show-export>
            </ts-table-operate>
        </div>
        <div v-if="isEmpty" class="search-result__container" style="margin-top: 20px;padding-top: 100px;">
            <ts-empty></ts-empty>
        </div>
        <div v-else class="search-result-body">
            <el-tabs v-model="tab" type="border-card">
                <el-tab-pane name="overview"><span slot="label">{{ pageMap.overview }}</span></el-tab-pane>
                <el-tab-pane name="province"><span slot="label">{{ pageMap.province }}</span></el-tab-pane>
                <el-tab-pane name="invSale"><span slot="label">{{ pageMap.invSale }}</span></el-tab-pane>
            </el-tabs>
        </div>

        <div class="search-result__container" v-if="loaded && !isEmpty">
            <div class="search-result__panel" :class="{ show: tab === 'overview' }">
                <overview-total v-if="finished.overview" ref="overviewTotal" :visit-id="visitId"
                    @empty="isEmpty = true"></overview-total>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'province' }">
                <overview-province :cols="cols" v-if="finished.province" ref="overviewProvince"
                    :visit-id="visitId"></overview-province>
            </div>
            <div class="search-result__panel" :class="{ show: tab === 'invSale' }">
                <overview-inv-sale v-if="finished.invSale" ref="overviewInvSale"
                    :visit-id="visitId"></overview-inv-sale>
            </div>
        </div>
    </div>
</template>

<script>
import OverviewProvince from './OverviewProvince'
import OverviewTotal from './OverviewTotal'
import OverviewInvSale from './OverviewInvSale'
export default {
    components: { OverviewTotal, OverviewProvince, OverviewInvSale },
    data() {
        return {
            visitId: 0,
            pageId: '',
            pageName: '',
            pageMap: {
                overview: '全国历年药品销售',
                province: '全国药品销售趋势',
                invSale: '药品销售查询'
            },

            tab: 'overview',
            loaded: false,
            finished: {
                overview: false,
                province: false,
                invSale: false
            },
            isEmpty: false,

            cols: this.$help.generalCols([{
                fieldName: 'comName',
                name: '药品',
                width: 80,
                listQueryView: '是',
                listQueryModel: 1,
                listView: '是',
                listOrderView: '是'
            },
            // {
            //     fieldName: 'unifySpec',
            //     name: '规格',
            //     width: 40,
            //     listQueryView: '是',
            //     listQueryModel: 1,
            //     // listStipulate: 1
            // },
            {
                fieldName: 'compName',
                name: '企业',
                width: 80,
                listQueryView: '是',
                listQueryModel: 1,
                listView: '是',
                listOrderView: '是'
                // listStipulate: 1
            },
            {
                fieldName: 'amount',
                name: '近年销售额',
                width: 60,
                listQueryView: '否',
                listQueryModel: 1,
                listOrderView: '是',
            },
            {
                fieldName: 'upOrDown',
                name: '近年趋势',
                width: 40,
                listQueryView: '否',
                listQueryModel: 1,
                listClass: 'tc',
                listOrderView: '是'
                // listStipulate: 1
            },
            {
                fieldName: 'rate',
                name: '近1年涨幅',
                width: 60,
                listQueryView: '否',
                listQueryModel: 1,
                listClass: 'tc',
                listOrderView: '是'
                // listStipulate: 1
            },
            {
                fieldName: 'price',
                name: '趋势图',
                width: 40,
                listQueryView: '否',
                listQueryModel: 1,
            }]),
        }
    },
    created() {
        this.init();
    },
    mounted() {
        this.$nextTick(() => {
            this.loaded = true
        })
    },
    watch: {
        '$route': function () {
            this.init()
        },
        tab: {
            handler(val) {
                this.finished[val] = true

                this.visitId = new Date().getTime()
                this.pageId = val
                this.pageName = '总览分析图-' + this.pageMap[val]

                this.$help.socket.send(this)
            },
            immediate: true
        },
    },
    methods: {
        //左侧筛选
        searchShowLeft() {
            if(this.tab === 'province'){
                this.$refs.overviewProvince.isSearchLeft = true
            }
        },
        init() {
            this.isEmpty = false
        },
        handleFilter(data) {
            if (this.tab === 'province') { //全国药品销售趋势
                this.$refs.overviewProvince && this.$refs.overviewProvince.onSearch(data)
            }
        },
        leadingOut() {
            if (this.tab === 'overview') { //全国历年药品销售
                this.$refs.overviewTotal && this.$refs.overviewTotal.exportInfo()
            } else if (this.tab === 'province') { //全国药品销售趋势
                this.$refs.overviewProvince && this.$refs.overviewProvince.exportInfo()
            } else if (this.tab === 'invSale') {
                this.$refs.overviewInvSale && this.$refs.overviewInvSale.exportInfo()
            }
        },
    },
}
</script>